/* Google Font CDN Link */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.container-form {
  width: 85%;
  margin-left: 100px;
  background: #fff;
  border-radius: 6px;
  padding: 20px 60px 30px 40px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
.container-form .content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.container-form .content .left-side {
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  position: relative;
}
.content .left-side::before {
  content: "";
  position: absolute;
  height: 70%;
  width: 2px;
  right: -15px;
  top: 50%;
  transform: translateY(-50%);
  background: #afafb6;
}
.content .left-side .details {
  margin: 14px;
  text-align: center;
}
.content .left-side .details i {
  font-size: 30px;
  color: #3e2093;
  margin-bottom: 10px;
}
.content .left-side .details .topic {
  font-size: 18px;
  font-weight: 500;
}
.content .left-side .details .text-one,
.content .left-side .details .text-two {
  font-size: 14px;
  color: #afafb6;
}
.container-form .content .right-side {
  width: 75%;
  margin-left: 75px;
}
.content .right-side .topic-text {
  font-size: 23px;
  font-weight: 600;
  color: #3e2093;
}
.right-side .input-box {
  height: 50px;
  width: 100%;
  margin: 12px 0;
}
.right-side .input-box input,
.right-side .input-box textarea {
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  font-size: 16px;
  background: #f0f1f8;
  border-radius: 6px;
  padding: 0 15px;
  resize: none;
}
.right-side .message-box {
  min-height: 110px;
}
.right-side .input-box textarea {
  padding-top: 6px;
}
.right-side .button {
  display: inline-block;
  margin-top: 12px;
}
.right-side .button input[type="button"] {
  color: #fff;
  font-size: 18px;
  outline: none;
  border: none;
  border-color: none;
  padding: 8px 16px;
  border-radius: 6px;
  background: #5029bc;
  cursor: pointer;
  transition: all 0.3s ease;
}

@media (max-width: 950px) {
  .container-form {
    width: 90%;
    padding: 30px 40px 40px 35px;
  }
  .container-form .content .right-side {
    width: 75%;
    margin-left: 55px;
  }
}
@media (max-width: 820px) {
  .container-form {
    margin: 40px 0;
    height: 100%;
  }
  .container-form .content {
    flex-direction: column-reverse;
  }
  .container-form .content .left-side {
    width: 100%;
    flex-direction: row;
    margin-top: 40px;
    justify-content: center;
    flex-wrap: wrap;
  }
  .container-form .content .left-side::before {
    display: none;
  }
  .container-form .content .right-side {
    width: 100%;
    margin-left: 0;
  }
}
li {
  list-style: none;
}

.has-text-blue {
  color: var(--primary-text) !important;
}

.has-background-blue {
  background-color: var(--primary-text) !important;
}

.has-background-blue50pr {
  background-color: var(--primary50pr);
}

/* header starts */

.header {
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: var(--primary-text) !important;
  transition: all 0.5s ease-in-out;
}

.header-fixed {
  padding: 15px 0;
}

.header-scrolled {
  padding: 2px 0;
}

.navbar {
  background-color: inherit;
}

.navbar-item {
  color: #fffd;
  background-color: inherit !important;
}

.navbar-item:hover,
.navbar-item:active {
  color: white !important;
}

.navbar-burger {
  color: white;
}

.navbar-menu {
  background-color: transparent;
}

/* header ends */

/* home starts */

.home-splash {
  background-color: var(--primary50pr);
}

/* home ends */

/* product starts */

.product-card {
  box-shadow: 2px 2px 5px 1px #1112;
  transition: all 0.5s ease-in-out !important;
}

.product-card:hover {
  box-shadow: 2px 2px 10px 3px #1112;
  transition: all 0.5s ease-in-out !important;
}

/* product ends */

.slider{
  margin: 0px auto;
}
